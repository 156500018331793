import { SetterOrUpdater } from 'recoil';

import { ProductApiResponse, ProductType } from '../../types/products';

import { compareObjectsByKey } from '../../utils';
import { Product } from 'src/types/subscriptions';
import { StateDropdownItem } from './select-products/SelectState';
import { Commodity } from './commodity-code-AI/LegacyCommodityCodes';

/**
 * @description calls setAgenciesByProduct with the agencies included in the provided product
 * @param allProducts - ProductApiResponse[] - the list of all products
 * @param productId - number - the ID of the product to check
 * @param setAgenciesByProduct - SetterOrUpdater<ProductApiResponse[]> - function
 * called to set agencies by product state
 * @param type - ProductType.State | ProductType.County - whether product is a state or county
 * @returns void
 * @example getAgenciesByProduct(allProducts, 10, setAgenciesByProduct, ProductType.County);
 */
export const getAgenciesByProduct = (
  allProducts: ProductApiResponse[],
  productId: number,
  setAgenciesByProduct: SetterOrUpdater<ProductApiResponse[]>,
  type: ProductType,
) => {
  let agenciesByProduct: ProductApiResponse[] = [];
  switch (type) {
    case ProductType.State:
      const childCounties = allProducts.filter(
        product =>
          product.productType === ProductType.County &&
          product.parentId &&
          product.parentId === productId,
      );
      agenciesByProduct = allProducts.filter(
        product =>
          product.productType === ProductType.FreeAgency &&
          childCounties.find(c => product.parentId === c.productId),
      );
      break;
    case ProductType.County:
      agenciesByProduct = allProducts.filter(
        product => product.productType === ProductType.FreeAgency && product.parentId === productId,
      );
      break;
  }
  setAgenciesByProduct(agenciesByProduct.sort(compareObjectsByKey('productName')));
};

/**
 * @description gets the agency count included in the provided product
 * @param allProducts - ProductApiResponse[] - the list of all products
 * @param productId - number - the ID of the product to check
 * @param type - ProductType - whether product is a agency, county, state, or nat'l
 * @returns number
 * @example const agencyCt = getAgencyCounts(allProducts, 10, ProductType.State);
 */
export const getAgencyCounts = (
  allProducts: ProductApiResponse[],
  productId: number,
  type: ProductType,
): number => {
  let agencyCount;
  switch (type) {
    case ProductType.FreeAgency:
      return 1;
    case ProductType.State:
      const childCounties = allProducts.filter(
        product =>
          product.productType === ProductType.County &&
          product.parentId &&
          product.parentId === productId,
      );
      agencyCount = allProducts.filter(
        product =>
          product.productType === ProductType.FreeAgency &&
          product.parentId &&
          childCounties.find(c => product.parentId === c.productId),
      );
      return agencyCount.length;
    case ProductType.County:
      agencyCount = allProducts.filter(
        product => product.parentId && product.parentId === productId,
      );
      return agencyCount.length;
    case ProductType.National:
      agencyCount = allProducts.filter(product => product.productType === ProductType.FreeAgency);
      return agencyCount.length;
    default:
      return 0;
  }
};

/**
 * @description finds the product by ID in the list of all products
 * @param productId - number - the ID of the product to check
 * @param allProducts - ProductApiResponse[] - the list of all products
 * @returns ProductApiResponse
 * @example const product = getProduct(10, allProducts);
 */
export const getProduct = (productId: number, allProducts: ProductApiResponse[]) => {
  const product = allProducts.find(product => product.productId === productId);
  return product;
};

/**
 * @description converts a set of ProductApiResponses into dropdown item format
 * @param stateProducts - ProductApiResponse[] - the list of states to convert
 * @returns StateDropdownItem[]
 * @example const stateDropdownItems = getStates(allProducts.filter(p => p.productType === ProductType.State));
 */
export const getStates = (stateProducts: ProductApiResponse[]): StateDropdownItem[] => {
  return stateProducts
    .map(product => {
      const value = product.productId.toString();
      return {
        key: product.productId,
        label: product.productName,
        title: product.productName,
        value: value,
      };
    })
    .sort(compareObjectsByKey('label'));
};

export const isSubscribed = (product: ProductApiResponse, subscriptions: Product[]): boolean => {
  if (!subscriptions?.length || !product?.productId) return false;

  const isSubscribedToNational = subscriptions?.filter(p => p.productType === ProductType.National)
    ?.length
    ? true
    : false;
  const isSubscribedToProductOrParent = subscriptions?.filter(
    p =>
      p.productId === product.productId || (product.parentId && p.productId === product.parentId),
  )?.length
    ? true
    : false;
  return isSubscribedToNational || isSubscribedToProductOrParent;
};
export const mergeCommodityCodes = (commodityCodesData, codesFromLegacy) => {

  const formattedLegacyCodes = codesFromLegacy?.map((legacyCode) => {
    return {
        fullcode: legacyCode?.fullCode,
        commodityid:legacyCode?.commodityId.toString() ,
        commodity: legacyCode?.commodityDescription,
    };
})||[];

const commodityCodes = commodityCodesData?.value?.filter(item => item?.['@search.rerankerScore'] != null) || [];
const topScore = commodityCodes[0]?.['@search.rerankerScore'] || 0;
const score = topScore > 2.15 ? 2.15 : topScore - 1;
const formattedCognitiveCodes = commodityCodes.filter(item => item['@search.rerankerScore'] > score);
const combinedCodes = [ ...formattedLegacyCodes,...formattedCognitiveCodes ];

const mergedCommodityCodes = Array?.from(
  combinedCodes?.reduce((map, code) => {
    map.set(code?.commodityid, code);
    return map;
  }, new Map()).values(),
);
  return mergedCommodityCodes;
};
export const findLegacyCommodities = (commMap: Map<string, Commodity>, text: string) => {
  const foundCommodities = findCommodities(Array.from(commMap.values()), text);
  // const categories = findCommoditiesCategories(commMap, foundCommodities);
  return foundCommodities;
};


export const findCommodities = (comms: Commodity[], text: string) => {
  if (!text) {
    return comms.filter((comm: Commodity) => {
      return comm.isSelected;
    });
  }

  const upperCase = text.toUpperCase();
  return comms.filter((comm: Commodity) => {
    return comm.isSelected || comm.commodityDescription.toUpperCase().trim() === upperCase.trim();
  });
};

export const findCommoditiesCategories = (
  commMap: Map<string, Commodity>,
  comms: Commodity[],
): Commodity[] => {
  const categoryCodes = new Set<string>([]);

  comms.forEach((comm: Commodity) => {
    if (comm.commodityCategory === '000') {
      return;
    }

    categoryCodes.add(`${comm.commodityGroup}-${comm.commodityCategory}-00`);
  });

  return Array.from(categoryCodes).reduce((comms: Commodity[], code: string) => {
    if (commMap.has(code)) {
      const comm = commMap.get(code);
      if (comm) {
        comms.push(comm);
      }
    }
    return comms;
  }, []);
};

export const commonTags =[
  'to', 'cha', 'services', 'verbs', 'le', 'sae', 'mata', 'stir',
  'support', 'of', 'fat', '&', 'by', 'sale', 'categories', 'chew',
  'nouns', 'on', 'then', 'items', 'eat', '-', 'how', 'and', 'for',
  'low', 'service', 'gra', 'healthy', 'buy', 'lot', 'management',
  'keywords', 'api', 'adjectives',
];
